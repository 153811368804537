import React from "react";

import Correo from "../../components/correo/correo.component";

const CorreoPage = () => {
  return (
    <div>
      <Correo />
    </div>
  );
};

export default CorreoPage;
